/* eslint-disable */
// https://dev2.bhsn.it/api/signity/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@repo/components/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'signity';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * Esign 접속정보 가져오기
 * @returns SignityResponseEsignAccessDtoV1 OK
 * @throws ApiError
 */
export function getAccess(_?: any, requestConfig?: RequestConfig): AxiosPromise<SignityResponseEsignAccessDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/esign/access/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(_?: any, requestConfig?: RequestConfig): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 상세 조회
 * @returns SignityResponseSealDetailDto OK
 * @throws ApiError
 */
export function getSeal(
    {
        sealId,
    }: {
        sealId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seals/{sealId}/v1',
                path: {
                    sealId: sealId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 수정
 * @returns SignityResponseSealCreatedDto OK
 * @throws ApiError
 */
export function updateSeal(
    {
        sealId,
        requestBody,
    }: {
        sealId: number;
        requestBody: SignitySealUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/seals/{sealId}/v1',
                path: {
                    sealId: sealId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 삭제
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function deleteSeal(
    {
        sealId,
    }: {
        sealId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/seals/{sealId}/v1',
                path: {
                    sealId: sealId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감을 사용불가로 변경하기
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function makeSealUnavailable(
    {
        sealId,
    }: {
        sealId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/seals/{sealId}/unavailable/v1',
                path: {
                    sealId: sealId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 상세 조회
 * @returns SignityResponseSealUsageRequestDetailDto OK
 * @throws ApiError
 */
export function getSealUsageRequest(
    {
        sealUsageRequestId,
    }: {
        sealUsageRequestId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealUsageRequestDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/{sealUsageRequestId}/v1',
                path: {
                    sealUsageRequestId: sealUsageRequestId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 수정
 * @returns SignityResponseSealUsageRequestCreatedDto OK
 * @throws ApiError
 */
export function updateSealUsageRequest(
    {
        sealUsageRequestId,
        requestBody,
    }: {
        sealUsageRequestId: number;
        requestBody: SignitySealUsageRequestUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealUsageRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/seal/usage-requests/{sealUsageRequestId}/v1',
                path: {
                    sealUsageRequestId: sealUsageRequestId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 삭제
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function deleteSealUsageRequest(
    {
        sealUsageRequestId,
    }: {
        sealUsageRequestId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/seal/usage-requests/{sealUsageRequestId}/v1',
                path: {
                    sealUsageRequestId: sealUsageRequestId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자서명 상태 동기화하기
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function postSignatureV1(
    {
        signatureId,
    }: {
        signatureId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signatures/{signatureId}/sync-status/v1',
                path: {
                    signatureId: signatureId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 서명상태 조회하기 SignityV1
 * @returns SignityResponseList_V1 OK
 * @throws ApiError
 */
export function postMySignStatusV1(
    {
        requestBody,
    }: {
        requestBody: SignityV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseList_V1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signatures/my-sign-status/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 복사된 인감 조회
 * @returns SignityResponseCopiedSealDto OK
 * @throws ApiError
 */
export function getCopiedSeal(
    {
        sealId,
    }: {
        sealId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseCopiedSealDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/seals/{sealId}/copy/v1',
                path: {
                    sealId: sealId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 목록 페이징 조회
 * @returns SignityResponsePageSealDto OK
 * @throws ApiError
 */
export function pageByCondition(
    {
        createdDateStart,
        createdDateEnd,
        searchKeyword,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponsePageSealDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seals/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    searchKeyword: searchKeyword,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 등록
 * @returns SignityResponseSealCreatedDto OK
 * @throws ApiError
 */
export function createSeal(
    {
        requestBody,
    }: {
        requestBody: SignitySealCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/seals/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 목록 페이징 조회
 * @returns SignityResponsePageSealUsageRequestDto OK
 * @throws ApiError
 */
export function pageByCondition1(
    {
        status,
        createdDateStart,
        createdDateEnd,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 인감 사용 신청 상태
         */
        status?: string;
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponsePageSealUsageRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/v1',
                query: {
                    status: status,
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청
 * @returns SignityResponseSealUsageRequestCreatedDto OK
 * @throws ApiError
 */
export function createSealUsageRequest(
    {
        requestBody,
    }: {
        requestBody: SignitySealUsageRequestCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSealUsageRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/seal/usage-requests/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 승인/반려
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function doSealUsageRequest(
    {
        requestBody,
    }: {
        requestBody: SignitySealUsageRequestActionForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/seal/usage-requests/action/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감을 사용하기(인감 사용 이력을 기록하기) SignityV1
 * @returns SignityResponse OK
 * @throws ApiError
 */
export function useSeal(
    {
        requestBody,
    }: {
        requestBody: SignityV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/seal/usage-histories/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 리뷰자 조회
 * @returns SignityResponseListGroupUserDto OK
 * @throws ApiError
 */
export function getSealUsageRequestReviewers(_?: any, requestConfig?: RequestConfig): AxiosPromise<SignityResponseListGroupUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/seal-usage-request-reviewers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자서명 조회하기
 * @returns SignityResponseSignatureDto OK
 * @throws ApiError
 */
export function getSignatureV1(
    {
        signatureId,
    }: {
        signatureId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSignatureDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/signatures/{signatureId}/v1',
                path: {
                    signatureId: signatureId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자서명 히스토리 조회하기
 * @returns SignityResponseSignatureHistoryDto OK
 * @throws ApiError
 */
export function getHistoryV1(
    {
        referenceType,
        referenceId,
    }: {
        /**
         * 연관 서비스의 타입 (예: conhub이면 `CONTRACT`)
         */
        referenceType: 'CONTRACT';
        /**
         * 연관 서비스의 식별자 (예: conhub이면 `contract.id`)
         */
        referenceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseSignatureHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/signatures/history/v1',
                query: {
                    referenceType: referenceType,
                    referenceId: referenceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 이미지 다운로드
 * @returns SignityStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadSealImage(
    {
        sealId,
        imageFileKey,
    }: {
        sealId: number;
        imageFileKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seals/{sealId}/image/{imageFileKey}/download/v1',
                path: {
                    sealId: sealId,
                    imageFileKey: imageFileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 유효한 인감 목록 조회
 * @returns SignityResponseListSealOverviewDto OK
 * @throws ApiError
 */
export function listValidSeal(_?: any, requestConfig?: RequestConfig): AxiosPromise<SignityResponseListSealOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seals/valid/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 메타 조회
 * @returns SignityResponseMapStringListObject OK
 * @throws ApiError
 */
export function getSealMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<SignityResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seals/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 신청 도큐먼트 다운로드
 * @returns SignityStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadSealUsageRequestDocument(
    {
        sealUsageRequestId,
        sealUsageRequestDocumentId,
    }: {
        sealUsageRequestId: number;
        sealUsageRequestDocumentId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/{sealUsageRequestId}/documents/{sealUsageRequestDocumentId}/download/v1',
                path: {
                    sealUsageRequestId: sealUsageRequestId,
                    sealUsageRequestDocumentId: sealUsageRequestDocumentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 이력 목록 페이징 조회
 * @returns SignityResponsePageProcessedSealUsageRequestDto OK
 * @throws ApiError
 */
export function pageProcessed(
    {
        statusUpdatedDateStart,
        statusUpdatedDateEnd,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        statusUpdatedDateStart?: string;
        /**
         * 조회 종료일
         */
        statusUpdatedDateEnd?: string;
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponsePageProcessedSealUsageRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/processed/v1',
                query: {
                    statusUpdatedDateStart: statusUpdatedDateStart,
                    statusUpdatedDateEnd: statusUpdatedDateEnd,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 인감 사용 이력 목록 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadProcessed(
    {
        statusUpdatedDateStart,
        statusUpdatedDateEnd,
        sortDirection,
    }: {
        /**
         * 조회 시작일
         */
        statusUpdatedDateStart?: string;
        /**
         * 조회 종료일
         */
        statusUpdatedDateEnd?: string;
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/processed/download/v1',
                query: {
                    statusUpdatedDateStart: statusUpdatedDateStart,
                    statusUpdatedDateEnd: statusUpdatedDateEnd,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 나의 승인해야 할 신청 목록 조회
 * @returns SignityResponsePageApprovalSealUsageRequestDto OK
 * @throws ApiError
 */
export function getToBeApprovedSealUsageRequest(
    {
        createdDateStart,
        createdDateEnd,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        createdDateStart?: string;
        /**
         * 조회 종료일
         */
        createdDateEnd?: string;
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponsePageApprovalSealUsageRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-requests/my-related/to-be-approved/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용 가능한 인감을 조회하기 SignityV1
 * @returns SignityResponseList_V1 OK
 * @throws ApiError
 */
export function getAvailableSeals(
    {
        externalDocumentId,
    }: {
        /**
         * 전자서명의 external document ID
         */
        externalDocumentId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<SignityResponseList_V1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/seal/usage-histories/available/v1',
                query: {
                    externalDocumentId: externalDocumentId,
                },
            }),
            requestConfig,
        ),
    );
}
