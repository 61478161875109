/* eslint-disable */
// https://dev2.bhsn.it/api/esign/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@repo/components/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'esign';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * 서명자가 열람하기 EsignV1
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function viewDocumentV1(
    {
        xSignerToken,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/signers/view/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 서명자가 서명하기 EsignV1
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function signDocumentV1(
    {
        xSignerToken,
        requestBody,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
        requestBody: EsignSignerSignFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/signers/sign/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 서명자가 반려하기 EsignV1
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function rejectDocumentV1(
    {
        xSignerToken,
        requestBody,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
        requestBody: EsignSignerRejectFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/signers/reject/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * OTP 검증하기 EsignV1
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function verifyOtpV1(
    {
        xSignerToken,
        otp,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
        /**
         * OTP
         */
        otp: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signers/verification/otp/verify/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
                query: {
                    otp: otp,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * OTP 발송하기 EsignV1
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function sendOtpV1(
    {
        xSignerToken,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signers/verification/otp/send/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 이메일 주소 검증하기 V2
 * @returns EsignResponse OK
 * @throws ApiError
 */
export function verifyEmailV2(
    {
        xSignerToken,
        requestBody,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
        requestBody: EsignEmailVerifyFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signers/verification/email/verify/v2',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 간편인증 토큰 검증하기 EsignV1 - 간편인증 javascript client에서 간편인증 결과 토큰을 전달한다.
 * @returns EsignEasyAuthResultDtoV1 OK
 * @throws ApiError
 */
export function verifyEasyauthV1(
    {
        signerToken,
        requestBody,
    }: {
        signerToken: string;
        requestBody: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignEasyAuthResultDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/signers/verification/easy-auth/verify/v1',
                query: {
                    signerToken: signerToken,
                },
                body: requestBody,
                mediaType: 'text/plain',
            }),
            requestConfig,
        ),
    );
}
/**
 * 전자서명 요청 생성하기 EsignV1
 * @returns EsignResponse_V1 OK
 * @throws ApiError
 */
export function createDocumentV1(
    {
        xApiKey,
        requestBody,
    }: {
        /**
         * API Key
         */
        xApiKey: string;
        requestBody: EsignDocumentCreateFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponse_V1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/documents/v1',
                headers: {
                    'X-API-Key': xApiKey,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 수행해야 하는 본인확인 방법 조회하기 EsignV1. 서명자 세션 토큰이 발행된다.
 * @returns EsignResponseVerificationMethodsDtoV1 OK
 * @throws ApiError
 */
export function getVerificationMethodsAndSessionTokenV1(
    {
        xUrlToken,
    }: {
        /**
         * URL 토큰
         */
        xUrlToken: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponseVerificationMethodsDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/signers/verification/methods/v1',
                headers: {
                    'X-URL-Token': xUrlToken,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 원본 pdf 다운로드하기 EsignV1
 * @returns binary OK
 * @throws ApiError
 */
export function downloadPdfForSignerV1(
    {
        xSignerToken,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/signers/download/pdf/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 문서 정보 조회하기 EsignV1
 * @returns EsignResponseSignerDocumentDtoV1 OK
 * @throws ApiError
 */
export function getDocumentForSignerV1(
    {
        xSignerToken,
    }: {
        /**
         * 서명자 토큰
         */
        xSignerToken: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<EsignResponseSignerDocumentDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/signers/documents/v1',
                headers: {
                    'X-Signer-Token': xSignerToken,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(_?: any, requestConfig?: RequestConfig): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
