/* eslint-disable */
// https://dev2.bhsn.it/api/arxive/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@repo/components/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'arxive';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * @returns ArxiveResponse OK
 * @throws ApiError
 */
export function updateV2(
    {
        fileKey,
        requestBody,
    }: {
        fileKey: string;
        requestBody: ArxiveFileUpdateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/files/{fileKey}/v2',
                path: {
                    fileKey: fileKey,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns binary OK
 * @throws ApiError
 */
export function download(
    {
        fileKey,
    }: {
        fileKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/files/{fileKey}/v1',
                path: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * @returns ArxiveResponse OK
 * @throws ApiError
 */
export function update(
    {
        fileKey,
        requestBody,
    }: {
        fileKey: string;
        requestBody: ArxiveFileUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/files/{fileKey}/v1',
                path: {
                    fileKey: fileKey,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseFileRevisionDto Created
 * @throws ApiError
 */
export function uploadRevision(
    {
        fileKey,
        formData,
    }: {
        fileKey: string;
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/files/{fileKey}/v1',
                path: {
                    fileKey: fileKey,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponse OK
 * @throws ApiError
 */
export function _delete(
    {
        fileKey,
    }: {
        fileKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/files/{fileKey}/v1',
                path: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseFileRevisionDto Created
 * @throws ApiError
 */
export function uploadV2(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/files/v2',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseListFileDto OK
 * @throws ApiError
 */
export function getFiles(
    {
        condition,
    }: {
        condition: ArxiveFileCondition;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseListFileDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/files/v1',
                query: {
                    condition: condition,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseFileRevisionDto Created
 * @throws ApiError
 */
export function upload(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
            tenantId: number;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/files/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponse OK
 * @throws ApiError
 */
export function deleteFiles(
    {
        requestBody,
    }: {
        requestBody: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/files/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns binary OK
 * @throws ApiError
 */
export function downloadByRevision(
    {
        fileKey,
        revision,
    }: {
        fileKey: string;
        revision: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/files/{fileKey}/{revision}/v1',
                path: {
                    fileKey: fileKey,
                    revision: revision,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseListFileRevisionDto OK
 * @throws ApiError
 */
export function getFileRevisions(
    {
        fileKey,
    }: {
        fileKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseListFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/files/{fileKey}/revisions/v1',
                path: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseFileRevisionDto OK
 * @throws ApiError
 */
export function getFileRevision(
    {
        fileKey,
        revision,
    }: {
        fileKey: string;
        revision?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/files/{fileKey}/revision/v1',
                path: {
                    fileKey: fileKey,
                },
                query: {
                    revision: revision,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseSearchAfterResultLawDataPrecedentDto OK
 * @throws ApiError
 */
export function searchAfter(
    {
        searchWord,
        previousSortValues,
    }: {
        searchWord: string;
        previousSortValues?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseSearchAfterResultLawDataPrecedentDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-data/precedents/search-after',
                query: {
                    searchWord: searchWord,
                    previousSortValues: previousSortValues,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns ArxiveResponseListPrecedentDto OK
 * @throws ApiError
 */
export function getPrecedents(
    {
        courtName,
        caseNumber,
    }: {
        courtName: string;
        caseNumber: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<ArxiveResponseListPrecedentDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/precedents/{courtName}/{caseNumber}/v1',
                path: {
                    courtName: courtName,
                    caseNumber: caseNumber,
                },
            }),
            requestConfig,
        ),
    );
}
